import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { constants } from "../redux/constants";
import AppSupportCategory from "./AppSupportCategory";

interface LearnMoreSectionProps {
    country: string;
    page?: string;
}

export const articles = {
    canada: {
        getAPlan: [
            { title: 'Which plans are available at PhoneBox?', articleId: 37 },
            { title: 'PhoneBox plans have a lock-in contract?', articleId: 40 },
            { title: 'How does a Prepaid Plan work?', articleId: 9 },
            { title: 'Does a prepaid line expire?', articleId: 59 },
            { title: 'How does a Monthly Plan work?', articleId: 8 },
            { title: 'What is the difference between prepaid and monthly accounts?', articleId: 13 },
            { title: 'Is my device compatible with PhoneBox network?', articleId: 66 },
            { title: 'Can I sign up for a PhoneBox plan if I am under 18 years old?', articleId: 68 },
            { title: 'Why should I choose PhoneBox over other mobile providers?', articleId: 39 },
        ],
        eSIM: [
            { title: 'What is eSIM? ', articleId: 60 },
            { title: 'What plans are available with eSIM at PhoneBox? ', articleId: 61 },
            { title: 'Are all devices compatible with eSIM?', articleId: 63 },
        ],
        signUp: [
            { title: 'Register for an online account', articleId: 158 },
            { title: 'What are the services and options available on Self-Serve?', articleId: 200 },
        ],
        homeInternet: [
            { title: 'Who is the Home Internet main provider?', articleId: 211 },
            { title: 'How to know if I\'m eligible to sign up?', articleId: 212 },
            { title: 'What\'s the available coverage?', articleId: 213 },
            { title: 'Are there any restrictions when signing up for this Home Internet Plan?', articleId: 214 },
        ]
    },
    usa: {
        getAPlan: [
            { title: 'What is the PhoneBox\'s US prepaid plan?', articleId: 99 },
            { title: 'How do I sign up for a US prepaid plan at PhoneBox?', articleId: 101 },
        ],
        simCard: [
            { title: 'Is it possible to purchase a SIM card outside of the USA?', articleId: 198 },
            { title: 'I don\'t have a SIM card yet. How do I sign up for a service with PhoneBox?', articleId: 184 },
        ],
        phoneNumber: [
            { title: 'Can I choose my phone number when I sign up?', articleId: 197 },
            { title: 'Am I able to transfer my existing phone number to PhoneBox?', articleId: 128 },
        ],
        activation: [
            { title: 'How to activate my US prepaid plan?', articleId: 100 },
            { title: 'How to activate PhoneBox service under eSIM? ', articleId: 183 },
        ],
        signUp: [
            { title: 'How to Sign-Up to use Self-Serve?', articleId: 191 },
            { title: 'What are the services and options available on Self-Serve?', articleId: 193 },
        ],
        paymentMethods: [
            { title: 'What are the accepted payment methods? ', articleId: 107 },
            { title: 'Can I use an international credit card to pay for a US phone number?', articleId: 114 },
        ],
        data: [
            { title: 'What happens if I reach the data limit?', articleId: 189 },
        ],
        internationalCalling: [
            { title: 'Does PhoneBox offer international calling?', articleId: 216 },
            { title: 'List of countries for International Calling', articleId: 217 },
        ],
    }
};

const LearnMoreSection: React.FC<LearnMoreSectionProps> = (props: LearnMoreSectionProps) => {
    return (
        <div>
            <Title>
                <Trans>Learn More</Trans>
            </Title>
            {props.page === 'home-internet' &&
              <Row>
                <Col sm={12} md={12} lg={6}>
                    {props.country === constants.CANADA &&
                        <>
                            <AppSupportCategory articles={articles.canada.homeInternet} title="Home Internet"></AppSupportCategory>
                        </>
                    }
                    
                </Col>
                <Col sm={12} md={12} lg={6}>
                    
                </Col>
              </Row>
            }
            {props.page !== 'home-internet' && 
              <Row>
                <Col sm={12} md={12} lg={6}>
                    {props.country === constants.CANADA &&
                        <>
                            <AppSupportCategory articles={articles.canada.getAPlan} title="Get a Plan"></AppSupportCategory>
                            <AppSupportCategory articles={articles.canada.eSIM} title="eSIM"></AppSupportCategory>
                        </>
                    }
                    {props.country === constants.USA &&
                        <>
                            <AppSupportCategory articles={articles.usa.getAPlan} title="Get a Plan"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.simCard} title="SIM Card"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.phoneNumber} title="Phone Number"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.activation} title="Activation"></AppSupportCategory>
                        </>
                    }
                </Col>
                <Col sm={12} md={12} lg={6}>
                    {props.country === constants.CANADA &&
                        <>
                            <AppSupportCategory articles={articles.canada.signUp} title="Sign Up"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.internationalCalling} title="International Calling"></AppSupportCategory>
                        </>
                    }
                    {props.country === constants.USA &&
                        <>
                            <AppSupportCategory articles={articles.usa.signUp} title="Sign Up"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.paymentMethods} title="Payment Methods"></AppSupportCategory>
                            <AppSupportCategory articles={articles.usa.data} title="Data"></AppSupportCategory>
                        </>
                    }
                </Col>
              </Row>
            }
        </div>
    )
};

const Title = styled.p`
  font-size: 200%;
  font-weight: 650;
  margin-bottom: 10px;

	@media (max-width: 992px) {
		font-size: 22px;
		padding-left: 1rem;
	}
`;
export default LearnMoreSection;