import React, { useState } from "react";
import styled from "styled-components";
import AppSupportArticle from "./AppSupportArticle";
import { Trans } from "react-i18next";

interface AppSupportCategoryProps {
  articles: any[];
  title: string;
}

const AppSupportCategory: React.FC<AppSupportCategoryProps> = (props: AppSupportCategoryProps) => {
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const rotateArrow = () => {
    setIsArrowRotated(!isArrowRotated);
  };

  return (
    <div>
      <OuterBar onClick={() => rotateArrow()}>
        <div style={{ fontWeight: 450 }}>
          <Trans>{props.title}</Trans>
        </div>
        <ArrowImage
          src="/images/support/right-arrow.png"
          isArrowRotated={isArrowRotated}
          height={20}
          width={20}
          alt="right-arrow"
        />
      </OuterBar>
      {isArrowRotated && props.articles.map((a, i) =>{
        return (<AppSupportArticle key={i} articleId={a.articleId} title={a.title} />)
      })}
    </div>
  );
};

const BAR_LENGTH = "80%";

const ArrowImage = styled.img<{ isArrowRotated: boolean }>`
  display: block;
  transition: all 0.4s ease;
  transform: ${(props) =>
    props.isArrowRotated ? "rotate(90deg) !important" : "rotate(0deg) !important"};
`;

const OuterBar = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: ${BAR_LENGTH};
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0px;
  }
  :hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 32em;
  }
  @media (max-width: 620px) {
    width: 30em;
  }
  @media (max-width: 560px) {
    width: 28em;
  }
  @media (max-width: 520px) {
    width: 26em;
  }
  @media (max-width: 480px) {
    width: 24em;
  }
  @media (max-width: 440px) {
    width: 22em;
  }

  @media (max-width: 390px) {
    width: 20rem;
  }
`;

export default AppSupportCategory;