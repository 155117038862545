import { constants } from "../constants";

export const resetPlansState = () => {
  return {
    type: constants.RESET_PLANS_STATE,
  };
};

export const updatePlanObject = (shopObject) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_PLANS_STATE,
    payload: shopObject,
  });
};